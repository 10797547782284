<!-- This file is for referencing while you are making a PNotify module. -->
{#if putThing} <!-- We want to check to make sure the notice should include our thing. -->
  <!--
  We're going to create a button that will be appended to the notice.
  It will be disabled by default, so we can enable it on mouseover.
  -->
  <button
      ref:thingElem
      class="ui-pnotify-reference-button btn btn-default"
      type="button"
      disabled="{!_mouseIsIn}"
      on:click="doSomething()">
    <i class="{_notice.get()._icons.athing}" />&nbsp;{labels.text}
  </button>
  <!-- Since our button is floated, we have to add a clearing div. -->
  <div class="ui-pnotify-reference-clearing" />
{/if}

<script>
  import PNotify from './PNotify.html';

  export default {
    setup (Component) {
      // This is the key you use for registering your module with PNotify.
      Component.key = 'Reference';

      // This if the default values of your options.
      Component.defaults = {
        // Provide a thing for stuff. Turned off by default.
        putThing: false,
        // If you are displaying any text, you should use a labels options to
        // support internationalization.
        labels: {
          text: 'Spin Around'
        }
      };

      // This is the first way to init a module. If you aren't placing any
      // markup in the template, you would do this.
      // Component.init = (_notice) => {
      //   return new Component({target: document.body, data: {_notice}});
      // };

      // Register the module with PNotify.
      PNotify.modules.Reference = Component;
      // Append our markup to the container.
      PNotify.modulesAppendContainer.push(Component);

      // This is where you would add any styling or icons classes you are using in your code.
      Object.assign(PNotify.icons.brighttheme, {
        athing: 'bt-icon bt-icon-refresh'
      });
      Object.assign(PNotify.icons.bootstrap3, {
        athing: 'glyphicon glyphicon-refresh'
      });
      Object.assign(PNotify.icons.fontawesome4, {
        athing: 'fa fa-refresh'
      });
      Object.assign(PNotify.icons.fontawesome5, {
        athing: 'fas fa-sync'
      });
      if (!PNotify.icons.material) {
        PNotify.icons.material = {};
      }
      Object.assign(PNotify.icons.material, {
        athing: 'material-icons pnotify-material-icon-refresh'
      });
    },

    oncreate () {
      // This is the second way to init a module. Because we put markup in the
      // template, we have to fire this event to tell the core that we are ready
      // to receive our options.
      this.fire('init', { module: this });
    },

    data () {
      return Object.assign({
        '_notice': null, // The PNotify notice.
        '_options': {}, // The options for the notice.
        '_mouseIsIn': false
      }, PNotify.modules.Reference.defaults);
    },

    methods: {
      // This method is called from the core to give us our actual options.
      // Until it is called, our options will just be the defaults.
      initModule (options) {
        // Set our options.
        this.set(options);
        // Now that the notice is available to us, we can listen to events fired
        // from it.
        const { _notice } = this.get();
        _notice.on('mouseenter', () => this.set({ '_mouseIsIn': true }));
        _notice.on('mouseleave', () => this.set({ '_mouseIsIn': false }));
      },

      doSomething () {
        // Spin the notice around.
        let curAngle = 0;
        const { _notice } = this.get();
        const timer = setInterval(() => {
          curAngle += 10;
          if (curAngle === 360) {
            curAngle = 0;
            clearInterval(timer);
          }
          _notice.refs.elem.style.transform = 'rotate(' + curAngle + 'deg)';
        }, 20);
      },

      // I have nothing to put in these, just showing you that they exist. You
      // won't need to include them if you aren't using them.
      update () {
        // Called when the notice is updating its options.
      },
      beforeOpen () {
        // Called before the notice is opened.
      },
      afterOpen () {
        // Called after the notice is opened.
      },
      beforeClose () {
        // Called before the notice is closed.
      },
      afterClose () {
        // Called after the notice is closed.
      },
      beforeDestroy () {
        // Called before the notice is destroyed.
      },
      afterDestroy () {
        // Called after the notice is destroyed.
      }
    }
  };
</script>

<style>
  .ui-pnotify-reference-button {
    float: right;
  }
  .ui-pnotify-reference-clearing {
    clear: right;
    line-height: 0;
  }
</style>
